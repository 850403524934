import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
  Tab,
  Tabs,
  AppBar,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { MapTypeLabel, useStyles } from "src/containers/WatchRTCPeer/Expectations";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import TableFilter from "./TableFilter";
import { MapType } from "src/constants/watchRTCExpectation";
import MediaPreview from "./MediaPreview";
import LogList from "./LogList";
import { ProjectsContext } from "../../../../AppController";
import { AnalyzeRTCRule } from "../../../../Admin/ProjectProperties/constants/insightsConfig";

export interface Alert {
  time: number;
  alertType: "err" | "warn" | "info";
  channel: string;
  message: string;
  topic?: string;
  isAdminOnly?: boolean;
}
export const alertLabels = {
  err: "error",
  warn: "warning",
  info: "info",
};

const View = (props: any) => {
  const {
    warnings,
    warningsCount,
    mediaList,
    hasLogs,
    resourceList,
    stdout,
    standOutUrl,
    assetsFileNames,
    testIteration,
    testRun,
  } = props;
  const isAnalyzePage = testIteration.runName === "manual-upload";
  const classes = useStyles();
  const [all, setAll] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [checked, setChecked] = React.useState<boolean[]>([true, true]);
  const [filteredData, setFilteredData] = React.useState<Alert[]>([]);
  const [data, setData] = React.useState<Alert[]>([]);
  const [types, setTypes] = React.useState<string[]>([]);

  const { selectedProject } = React.useContext(ProjectsContext);
  const [insightsLinks, setInsightsLinks] = React.useState<{ code: number; link: string }[]>([]);

  React.useEffect(() => {
    if (selectedProject) {
      const mapper = (arr: AnalyzeRTCRule[]) =>
        arr.map((item) => ({
          code: item.id,
          link: item.link || "",
        }));

      setInsightsLinks([...mapper(selectedProject.insights_customisations.analyzeRTC)]);
    }
  }, [selectedProject]);

  React.useEffect(() => {
    if (warningsCount > 0) {
      const allAlerts = Object.values(warnings[0]).flat() as Alert[];
      const alertTypes = Array.from(new Set(allAlerts.map((alert) => alert.alertType)));
      setTypes(alertTypes);
      setData(allAlerts);
    }
  }, [warnings[0]]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // refactored to show rules engine error above all errors and rules engine warnings above all warnings
  const sort = (a: any, b: any) => {
    //return (a.type === "error" || a.alertType === "err") ? -1 : 0;
    if ((a.type === "error" || a.alertType === "err") && a.channel === "Insight") {
      return -1;
    } else if (
      (a.type === "error" || a.alertType === "err") &&
      !((b.type === "error" || b.alertType === "err") && b.channel === "Insight")
    ) {
      return -1;
    } else if (
      (a.type === "warning" || a.alertType === "warn") &&
      a.channel === "Insight" &&
      !(b.type === "error" || b.alertType === "err")
    ) {
      return -1;
    } else {
      return 0;
    }
  };

  return (
    <>
      <Grid className={classes.container}>
        <AppBar className={classes.toolbar}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={
                <span>
                  <span>{`Notifications ${warningsCount > 0 ? "(" + warningsCount + ")" : ""}`}</span>
                </span>
              }
            />
            {!isAnalyzePage && <Tab label="Media" />}
            {!isAnalyzePage && <Tab label="Logs" />}
          </Tabs>
        </AppBar>
        <Stack className={classes.filterContainer}>
          <Box
            sx={{
              width: expanded ? "150px" : "28px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
              display: warningsCount > 5 && value === 0 ? "flex" : "none",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F5F5F6",
                width: "100%",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#F5F5F6",
                },
                position: "relative",
                minHeight: "57px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <Button
                sx={{
                  padding: "12px 16px",
                  position: "absolute",
                  right: "-32px",
                  rotate: expanded ? "180deg" : "0deg",
                }}
                onClick={() => setExpanded(!expanded)}
              >
                <PlayCircleOutlineIcon
                  sx={{
                    color: "#0000008A",
                  }}
                />
              </Button>
            </Box>
            <TableFilter
              types={types}
              filteredData={data}
              setFilteredData={setFilteredData}
              checked={checked}
              setChecked={setChecked}
              expanded={expanded}
            />
          </Box>
          {value === 0 &&
            (filteredData.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.customColumn}>Channel</TableCell>
                    <TableCell className={classes.typeColumn}>Type</TableCell>
                    <TableCell className={classes.metricColumn}>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .sort(sort)
                    .slice(0, all ? filteredData.length : 2)
                    .map((ex, i) => {
                      const insightCode = ex.message.match(/^(\d+)/)?.[1];
                      const insightLink =
                        insightsLinks.find((insight) => insight.code === Number(insightCode))?.link ?? "";
                      return (
                        <TableRow key={i}>
                          <TableCell
                            sx={{
                              borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                              paddingLeft: "32px",
                            }}
                          >
                            {ex.channel}
                          </TableCell>
                          <TableCell>
                            <Tooltip title={"ex type"} placement="top">
                              <MapTypeLabel type={MapType[alertLabels[ex.alertType]]} />
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            {ex.channel !== "Insight" || !insightCode ? (
                              ex.message.includes("<a href=") ? (
                                <span dangerouslySetInnerHTML={{ __html: ex.message }} />
                              ) : (
                                ex.message
                              )
                            ) : (
                              <>
                                <a
                                  href={insightLink}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={classes.insightLink}
                                >
                                  {insightCode}
                                </a>
                                {ex.message.slice(insightCode.length)}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {filteredData.length > 2 && (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{
                          padding: 0,
                        }}
                      >
                        <Button onClick={() => setAll(!all)} className={classes.limitButton}>
                          {`Show ${all ? "less" : "all"}`}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="subtitle1">No warnings or errors reported</Typography>
            ))}
          {value === 1 && <MediaPreview mediaList={mediaList} />}
          {value === 2 && (
            <LogList
              assetsFileNames={assetsFileNames}
              stdout={stdout}
              standOutUrl={standOutUrl}
              hasLogs={hasLogs}
              resourceList={resourceList}
              test={testIteration}
              testRun={testRun}
            />
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default View;
